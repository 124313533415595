
//Libs
import { useEffect, useState } from 'react';
import React from 'react';
import moment from 'moment';
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react';
import { Bounce, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//Utils
import Loader from '../../../components/loader';
import UserNav from '../components/userNav';
import UserHeader from '../components/userHeader';
import Spinner from '../../../components/spinner';
import { errorMessage } from '../../../utils/utils';
import api from '../../../utils/api';

//Icons
import { TiEdit } from "react-icons/ti";
import { MdOutlineAccessTimeFilled } from 'react-icons/md';
import { BiWorld } from 'react-icons/bi';
import { FaExclamation, FaHammer } from 'react-icons/fa6';
import { FaArrowRight, FaInfoCircle, FaRegCheckCircle } from 'react-icons/fa';
import { PiWarningDiamondFill } from 'react-icons/pi';
import { IoIosArrowDown, IoIosArrowForward, IoIosCloseCircle } from 'react-icons/io';
import { FaCheck, FaQuestion } from 'react-icons/fa6';
import { RiDeleteBin5Fill } from 'react-icons/ri';
import { IoCopy } from "react-icons/io5";
import { HiWrenchScrewdriver } from "react-icons/hi2";
import { GrTools } from 'react-icons/gr';



export default function () {

    const [pageLoading, setPageLoading] = useState(true);

    const [nav, setNav] = useState(false);

    const [itemsError, setItemsError] = useState('');
    const [itemsLoading, setItemsLoading] = useState(true);
    const [items, setItems] = useState<any[]>([]);

    const [activeId, setActiveId] = useState('');

    const [confirmOpen, setConfirmOpen] = useState(false);
    const [confirmMessage, setConfirmMessage] = useState('');
    const [confirmTitle, setConfirmTitle] = useState('');

    const [actionId, setActionId] = useState('');
    const [action, setAction] = useState('');
    const [actionLoading, setActionLoading] = useState(false);
    const [actionError, setActionError] = useState('');
    const [actionMessage, setActionMessage] = useState('');

    useEffect(() => {
        if (!window.localStorage.token) {
            window.location.replace('/signin');
            return;
        }
        setPageLoading(false);
        getItems();

    }, []);


    function clearConfirm() {
        setConfirmOpen(false);

        if (actionMessage) {
            getItems();
        }

        setTimeout(() => {
            setActionId('');
            setAction('');
            setActionLoading(false);
            setActionMessage('');
            setActionError('');
        }, 500);
    }


    async function getItems() {
        setItems([]);
        setActiveId('');
        setItemsLoading(true);
        try {
            const res = await api.get(`/remote`);
            const json = await res.json();
            if (res.ok) {
                setItems(json);
            } else {
                setItemsError(errorMessage(json));
            }

        } catch (error) {
            setItemsError(errorMessage(error));
        }

        setItemsLoading(false);
    }

    async function delItem(id: string) {

        setActionLoading(true);

        try {
            const res = await api.delete(`/remote/${id}`);
            const json = await res.json();
            if (res.ok) {
                setActionMessage(json.message);
                // getItems();
            } else {
                setActionError(errorMessage(json));
            }

        } catch (error) {
            setActionError(errorMessage(error));
        }
        setActionLoading(false);

    }

    async function discItem(id: string) {

        setActionLoading(true);
        try {
            const res = await api.post(`/remote/disc/${id}`);
            const json = await res.json();
            if (res.ok) {
                setActionMessage(json.message);
                // getItems();
            } else {
                setActionError(errorMessage(json));
            }
        } catch (error) {
            setActionError(errorMessage(error));
        }
        setActionLoading(false);
    }

    async function fixItem(id: string) {

    }

    async function executeConfirm() {

        if (!actionId) {
            clearConfirm();
            return;
        }

        switch (action) {
            case 'delete':
                delItem(actionId);
                break;
            case 'disconnect':
                discItem(actionId);
                break;
            case 'recreate':
                fixItem(actionId);
                break;
            default:
                clearConfirm();
        }
    }

    function confirmAction(action: string, obj: any) {
        setActionId(obj.id);
        setAction(action);
        if (action === 'delete') {
            setConfirmTitle(`Delete ${obj.label ?? obj.user}`);
            setConfirmMessage(`Are you sure you want to delete <b>${obj.label ?? obj.user}?</b><br />This action cannot be undone.`);
        }

        if (action === 'disconnect') {
            setConfirmTitle(`Disconnect ${obj.label ?? obj.user}`);
            setConfirmMessage(`Are you sure you want to disconnect <b>${obj.label ?? obj.user}?</b><br />This account will automatically reconnect shortly.<br /><br />This action will resolve the missing src and dst configuration error, if present.`);
        }

        if (action === 'recreate') {
            setConfirmTitle(`Fix ${obj.label ?? obj.user}`);
            setConfirmMessage(`This will fix the error missing secret configuration for <b>${obj.label ?? obj.user}?</b>`);
        }

        setConfirmOpen(true);
    }

    if (pageLoading) {
        return (<div className='w-screen h-screen text-center flex flex-col bg-white justify-center'><Loader /></div>)
    }

    return (
        <>
            <div className='flex flex-row w-full'>

                <div className="hidden xl:block"><UserNav nav='remote' className='fixed block left-[0px]' /></div>

                <main className='xl:ml-[250px] grow w-screen flex flex-col'>
                    <UserHeader title='My Remote Access' setNav={() => { setNav(!nav) }} />
                    <section>

                        <div className='flex flex-row mx-10 my-5'>
                            <div className='grow'></div>
                            <button className='bg-green-500 px-5 shadow-sm rounded-sm py-1 text-white hover:bg-green-400 active:bg-green-600' onClick={() => {
                                window.location.href = `/user/remote/new`;
                            }}>New Remote</button>
                        </div>

                        <Small className='lg:hidden' items={items} loading={itemsLoading} error={itemsError} activeId={activeId} setActive={setActiveId} actionId={actionId} setAction={confirmAction} />
                        <Large className='hidden lg:block' items={items} loading={itemsLoading} error={itemsError} activeId={activeId} setActive={setActiveId} actionId={actionId} setAction={confirmAction} />

                    </section>
                </main>
            </div>

            {/* Confirm Dialog */}
            <Dialog open={confirmOpen} onClose={() => {
                if (!actionLoading && !actionError && !actionMessage) {
                    clearConfirm();
                }
            }} className="relative z-10">
                <DialogBackdrop
                    transition
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                />

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <DialogPanel
                            transition
                            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                        >
                            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">

                                    <div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-${actionError ? 'red' : actionMessage ? 'green' : 'blue'}-200 sm:mx-0 sm:h-10 sm:w-10`}>
                                        <FaQuestion aria-hidden="true" className={`h-6 w-6 text-blue-700 ${(actionError || actionMessage) && 'hidden'}`} />
                                        <FaCheck aria-hidden="true" className={`h-6 w-6 text-green-600 ${!actionMessage && 'hidden'}`} />
                                        <FaExclamation aria-hidden="true" className={`h-6 w-6 text-red-600 ${!actionError && 'hidden'}`} />
                                    </div>

                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">{confirmTitle}</DialogTitle>
                                        <div className="mt-2">
                                            <div className="text-sm text-gray-700"><div dangerouslySetInnerHTML={{ __html: actionError ? actionError : actionMessage ? actionMessage : confirmMessage }} /></div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="bg-gray-50 px-4 py-3 flex flex-row justify-center sm:justify-end gap-3">


                                <button
                                    className={`disabled:bg-gray-500 h-[30px] w-[80px] flex flex-row items-center justify-center bg-blue-600 text-sm font-medium rounded-md text-white shadow-sm hover:bg-blue-500 active:bg-blue-700 ${(actionError || actionMessage) && 'hidden'}`}
                                    disabled={actionLoading}
                                    onClick={executeConfirm}>
                                    {actionLoading && <Spinner className='inline mr-2' />}YES
                                </button>

                                <button
                                    type="button"
                                    // data-autofocus
                                    disabled={actionLoading}
                                    onClick={clearConfirm}
                                    className="disabled:bg-gray-500 inline-flex h-[30px] w-[80px] flex flex-row items-center justify-center rounded-md bg-white text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                >
                                    {actionError || actionMessage ? 'OK' : 'NO'}
                                </button>
                            </div>
                        </DialogPanel>
                    </div>
                </div>
            </Dialog>

            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
            />

            <div className='xl:hidden'>
                {nav && <div className="bg-black/80 fixed w-full h-screen z-10 top-0 left-0" onClick={() => setNav(false)}></div>}
                <div className={nav ? "fixed top-0 left-0 w-[250px] h-screen bg-white z-10 duration-300" : "fixed top-0 left-[-100%] w-[250px] h-screen bg-white z-10 duration-300"}>
                    <UserNav nav='remote' />
                </div>
            </div>
        </>
    )

}


type ItemObj = {
    items: any[],
    loading: boolean,
    error: string | TrustedHTML,
    actionId: string,
    setAction: (action: string, obj: any) => any,
    activeId: string,
    setActive: (id: string) => any,
    className?: string,
}

function Small({ items, loading, error, actionId, setAction, activeId, setActive, className }: ItemObj) {


    return (<div className={className}>

        <div className={`w-full px-5 ${!loading && 'hidden'}`}>
            <div className='mx-auto text-center'><Spinner className='w-8 h-8 inline text-blue-500 fill-gray-400' /></div>
        </div>

        <div className={`w-full px-5 ${loading && !error && 'hidden'}`}>
            <div className={`mx-auto text-center`}><div className='text-red-500 italic text-sm'><div dangerouslySetInnerHTML={{ __html: error }} /></div></div>
        </div>

        <ul className='w-full mb-5 sm:px-5 flex flex-col gap-3'>
            {
                items.map((item: any, index: number) => {

                    let toPort = item.to_port;

                    if (item.to_protocol && item.to_protocol !== 'tcp') {
                        toPort = `${item.to_protocol}:${item.to_port}`;
                    }

                    const expiration = item.date_expiration ? moment(item.date_expiration).format('YYYY-MM-DD @ hh:mm:ss a') : '(n/a)';

                    let daysLeft = '';

                    try {
                        const expDate = Date.parse(item.date_expiration?.toString());

                        if (expDate) {
                            const now = new Date();
                            const diff = (expDate - now.getTime()) / (1000 * 60 * 60 * 24);
                            daysLeft = diff.toFixed(0);
                        }
                    } catch (error) {

                    }

                    return <li key={`${index}-item`} className="bg-white px-5 py-3 flex flex-col rounded-md shadow-md">

                        <div>
                            <div className='flex flex-row items-center gap-2'>
                                <div className={`text-base ${item.errors?.length > 0 ? 'text-orange-500' : item.expired ? 'text-gray-400' : 'text-blue-500'}`}>{item.label ?? item.user ?? '(unknown)'}</div>
                                {item.status === 'online' && item.errors.length === 0 && <FaRegCheckCircle className='text-green-600' />}
                                {item.status === 'online' && <div className='group relative flex justify-center'>
                                    <HiWrenchScrewdriver className='text-blue-500 cursor-pointer active:text-blue-700' size={16}
                                        onClick={() => {
                                            if (typeof setAction === 'function') {
                                                setAction('disconnect', item);
                                            }
                                        }}
                                    />
                                    <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 min-w-[200px]">Click to reconnect or fix error missing src and dst config</span>
                                </div>}
                                {item.errors?.length > 0 && <div className='group relative flex justify-center'>
                                    <PiWarningDiamondFill className='inline text-orange-500' />
                                    <span className="whitespace-nowrap absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">{item?.errors.map((err: any, index: number) => { return <div key={`${index}-err`}><span>{err}</span><br /></div>; })}</span>
                                </div>}
                                {item.expired && <div className='group relative flex justify-center'>
                                    <IoIosCloseCircle className='text-gray-500' size={18} />
                                    <span className="whitespace-nowrap absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">expired</span>
                                </div>}
                            </div>
                        </div>

                        <div className='flex flex-row gap-1 items-center'>
                            <span className={`text-white rounded-md px-[4px] py-[0px] text-[10px] ${item.expired || item.status !== 'online' ? 'bg-gray-500' : 'bg-green-500'}`}>{item.expired ? 'expired' : item.status ?? 'unknown'}</span>
                            {item.uptime &&
                                <div className='text-xs px-[2px] py-[0px] border-[1.5px] border-gray-500 rounded-md flex flex-row items-center gap-1'>
                                    <MdOutlineAccessTimeFilled className='text-gray-500 h-[13px] w-[13px]' /><b>{item.uptime}</b>
                                </div>}

                            {item.remote_ip &&
                                <div className='text-xs px-[2px] py-[0px] border-[1.5px] border-gray-500 rounded-md flex flex-row items-center gap-1'>
                                    <BiWorld className='text-gray-500 h-[13px] w-[13px]' /><b>{item.remote_ip}</b>
                                </div>}
                        </div>

                        <div className='grow flex flex-row items-center gap-1'>

                            <div className='flex flex-col text-sm grow'>

                                <div className='flex flex-col'>
                                    <div className='text-xs'><span>Service: </span><span className='font-medium'>{item.service}</span></div>
                                    <div className='text-xs'><span>Bandwidth: </span><span className='font-medium'>{item.bandwidth}</span></div>
                                    {/* <div className='text-xs'><span>Server: </span><span className='font-bold text-blue-500'>{item.server}</span></div> */}
                                    <div className='text-xs'><span>Subscription: </span><span className='font-medium'>{item.subscription}</span></div>
                                    <div className='text-xs'><span>Application: </span><span className='font-medium'>{toPort}</span></div>
                                    <div className={`text-xs ${item.expired && expiration === '(n/a)' && 'hidden'}`}><span>Expired On: </span><span className='font-medium'>{expiration}</span>{`${daysLeft ? ` (${daysLeft} day${daysLeft === '1' ? '' : 's'})` : ''}`}</div>
                                    {/* <div className={`text-xs`}><span>Days Left: </span><span className='font-medium'>{daysLeft}</span></div> */}
                                </div>
                            </div>

                            <div>
                                <div className={`flex flex-row items-center justify-center ${actionId !== item.id && 'hidden'}`}><Spinner /></div>

                                <div className={`flex flex-col items-center justify-center gap-8 ${actionId === item.id && 'hidden'}`}>

                                    <div className={`group relative flex justify-center ${item.subscription === 'demo' && 'invisible'}`}>
                                        <TiEdit className='text-green-500 inline cursor-pointer active:text-black h-[20px] w-[20px]'
                                            onClick={actionId ? undefined : () => {
                                                window.location.href = `/user/remote/${item.ref ?? ''}`;
                                            }} />
                                    </div>

                                    {item.subscription !== 'demo' &&
                                        <div className='group relative flex justify-center'>
                                            <RiDeleteBin5Fill className='text-red-500 inline cursor-pointer active:text-black'
                                                onClick={actionId ? undefined : () => {
                                                    if (typeof setAction === 'function') {
                                                        setAction('delete', item);
                                                    }
                                                }} />
                                        </div>}
                                </div>
                            </div>

                        </div>

                        {item.id === activeId && <div className='flex flex-col gap-3'>

                            <div className='text-xs bg-gray-100 rounded-md mt-2 p-3 flex flex-col gap-2'>
                                <div>
                                    <div>Server:</div>
                                    <div className='flex flex-row items-center gap-2'>
                                        <div className={`font-bold ml-2 ${item.expired ? 'text-gray-400' : 'text-blue-500'}`}>{item.from_address}</div>
                                        <IoCopy className={`inline text-gray-500 cursor-pointer hover:text-blue-500 active:text-black ${item.expired && 'hidden'}`} size={12} onClick={() => navigator.clipboard.writeText(item.from_address)} />
                                    </div>
                                </div>
                                <div>
                                    Credentials:
                                    <ul className='ml-2 mt-1 flex flex-col gap-1'>
                                        <li className='flex flex-row items-center'>User:<b className={`mx-1 ${item.expired ? 'text-gray-400' : 'text-blue-500'}`}>{item.user}</b><IoCopy className={`inline text-gray-500 cursor-pointer hover:text-blue-500 active:text-black ${item.expired && 'hidden'}`} size={12} onClick={() => navigator.clipboard.writeText(item.user)} /></li>
                                        <li className='flex flex-row items-center'>Password:<b className={`mx-1 ${item.expired ? 'text-gray-400' : 'text-blue-500'}`}>{item.password}</b><IoCopy className={`inline text-gray-500 cursor-pointer hover:text-blue-500 active:text-black ${item.expired && 'hidden'}`} size={12} onClick={() => navigator.clipboard.writeText(item.password)} /></li>
                                    </ul>
                                </div>
                                <div>
                                    <div>Remote Url:</div>
                                    <div className='flex flex-row items-center gap-2'>
                                        <div className={`font-bold ml-2 ${item.expired ? 'text-gray-400' : 'text-blue-500'}`}>{item.to_port === '443' ? 'https://' : ''}{item.from_address}:{item.from_port}</div>
                                        <IoCopy className={`inline text-gray-500 cursor-pointer hover:text-blue-500 active:text-black ${item.expired && 'hidden'}`} size={12} onClick={() => navigator.clipboard.writeText(`${item.to_port === '443' ? 'https://' : ''}${item.from_address}:${item.from_port}`)} />
                                    </div>
                                </div>
                            </div>

                            <div className='text-xs bg-gray-100 rounded-md mt-2 p-3 flex flex-col gap-2'>

                                <div className='font-medium'>Tutorials</div>
                                <ul className='flex flex-col gap-2 ml-2'>

                                    <li>
                                        <a href={`/tutorials/how-to-connect-${item.service}-client`} rel="noreferrer" target='_blank' className='underline text-blue-500 font-medium'>How to install {item.service} client on mikoritk</a>
                                    </li>

                                    <li className={`${(item.service !== 'ovpn' || !item.ref) && 'hidden'}`}>
                                        <a href={`/tutorials/how-to-install-${item.service}-client-on-adopisoft`} rel="noreferrer" target='_blank' className='underline text-blue-500 font-medium'>How to install ovpn client on Adopisoft Orange PI/Raspberry PI</a>
                                        <div className='mt-2 px-2 flex flex-col gap-1'>
                                            <div>Installer file:</div>
                                            <div className='flex flex-row items-center gap-2'>
                                                <code className='bg-gray-700 text-white px-1 py-[1px] tracking-wide text-xs rounded-md flex flex-row items-center gap-1'>
                                                    <span className='text-[10px] break-all'>{process.env.REACT_APP_ENDPOINT_DL}/{item.ref ?? ''}/ovpn.sh</span>
                                                    <div className={`group relative flex justify-center ${item.expired && 'hidden'}`}>
                                                        <IoCopy className='inline text-white cursor-pointer hover:text-blue-500 active:text-black' size={10} onClick={() => navigator.clipboard.writeText(`${process.env.REACT_APP_ENDPOINT_DL}/${item.ref ?? ''}/ovpn.sh`)} />
                                                        <span className="whitespace-nowrap absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">Copy <span className='font-bold'>{process.env.REACT_APP_ENDPOINT_DL}/{item.ref ?? ''}/ovpn.sh</span></span>
                                                    </div>
                                                </code>
                                                <div className={`group relative flex justify-center`}>
                                                    <FaInfoCircle className='inline text-orange-500' size={15} />
                                                    <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 min-w-[200px]">Do not share this link to any one. This link contains user sensitive information</span>
                                                </div>
                                            </div>

                                        </div>

                                    </li>

                                    <li className={`${(item.service !== 'l2tp' || !item.ref) && 'hidden'}`}>
                                        <a /*href={`/tutorials/how-to-install-${item.service}-client-on-adopisoft-opi`}*/ rel="noreferrer" target='_blank' className='underline text-gray-500 font-medium'>How to install l2tp client on Adopisoft Orange PI</a>
                                        <div className='mt-2 px-2 flex flex-col gap-1'>
                                            <div>Installer file:</div>
                                            <div className='flex flex-row items-center gap-2'>
                                                <code className='bg-gray-700 text-white px-1 py-[1px] tracking-wide text-xs rounded-md flex flex-row items-center gap-1'>
                                                    <span className='text-[10px] break-all'>{process.env.REACT_APP_ENDPOINT_DL}/{item.ref ?? ''}/opi-l2tp.sh</span>
                                                    <div className={`group relative flex justify-center ${item.expired && 'hidden'}`}>
                                                        <IoCopy className='inline text-white cursor-pointer hover:text-blue-500 active:text-black' size={10} onClick={() => navigator.clipboard.writeText(`${process.env.REACT_APP_ENDPOINT_DL}/${item.ref ?? ''}/opi-l2tp.sh`)} />
                                                        <span className="whitespace-nowrap absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">Copy <span className='font-bold'>{process.env.REACT_APP_ENDPOINT_DL}/{item.ref ?? ''}/opi-l2tp.sh</span></span>
                                                    </div>
                                                </code>
                                                <div className={`group relative flex justify-center`}>
                                                    <FaInfoCircle className='inline text-orange-500' size={15} />
                                                    <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">Do not share this link to any one. This link contains user sensitive information</span>
                                                </div>
                                            </div>


                                        </div>

                                    </li>

                                </ul>

                            </div>

                        </div>}


                        <div className='text-center text-[10px] text-blue-500 mt-2 cursor-pointer' onClick={() => {
                            if (typeof setActive === 'function') {
                                setActive(item.id === activeId ? '' : item.id);
                            }
                        }}>{item.id === activeId ? 'Click here to hide this info' : 'Click here to show more info'}</div>
                    </li>
                })
            }
        </ul>
    </div>)
}

function Large({ items, loading, error, actionId, setAction, activeId, setActive, className }: ItemObj) {

    return <div className={`w-full mb-5 px-10 ${className && className}`}>
        <table className="table-fixed w-full divide-y divide-gray-300 bg-white shadow-md rounded-lg ring-1 ring-gray-900/5">
            <thead className='h-[60px] text-white text-left bg-blue-500'>
                <tr>
                    <th className='w-[30px] rounded-tl-lg'></th>
                    <th className='text-sm font-medium px-1'>Name</th>
                    <th className='text-sm font-medium px-1'>Remote URL</th>
                    <th className='text-sm font-medium px-1 w-[120px] text-center'>Bandwidth</th>
                    <th className='text-sm font-medium px-1 w-[80px] text-center'>Service</th>
                    {/* <th className='text-sm font-medium px-1 w-[120px] text-center'>Server</th> */}
                    <th className='text-sm font-medium px-1 w-[70px] text-center'>Status</th>
                    <th className='text-sm font-medium px-1 w-[60px] text-center'>Subs</th>
                    <th className='text-sm font-medium px-1 w-[80px] text-center rounded-tr-lg'></th>
                </tr>
            </thead>

            <tbody className={`divide-y divide-gray-200 ${!loading && 'hidden'}`}>
                <tr className='h-[40px]'>
                    <td colSpan={8} className='text-center'>
                        <div className='w-full'><Spinner className='w-8 h-8 inline text-blue-500 fill-gray-400' /></div>
                    </td>
                </tr>
            </tbody>

            <tbody className={`divide-y divide-gray-200 ${(loading || !error) && 'hidden'}`}>
                <tr className='h-[40px]'>
                    <td colSpan={8} className='text-center'>
                        <div className='text-red-500 italic text-sm'><div dangerouslySetInnerHTML={{ __html: error }} /></div>
                    </td>
                </tr>
            </tbody>

            <tbody className={`divide-y divide-gray-200 ${(loading || items?.length > 0 || error) && 'hidden'}`}>
                <tr className='h-[40px]'>
                    <td colSpan={8} className='text-center'>
                        <div className='italic text-sm'>No data</div>
                    </td>
                </tr>
            </tbody>


            <tbody className={`divide-y divide-gray-200 ${(items?.length === 0 || loading || error) && 'hidden'}`}>

                {items.map((item: any, index: number) => {

                    let toPort = item.to_port; //{item.to_protocol}:{item.to_port}

                    if (item.to_protocol && item.to_protocol !== 'tcp') {
                        toPort = `${item.to_protocol}:${item.to_port}`;
                    }

                    const expiration = item.date_expiration ? moment(item.date_expiration).format('YYYY-MM-DD @ hh:mm:ss a') : null;

                    let daysLeft;

                    try {
                        const expDate = Date.parse(item.date_expiration?.toString());

                        if (expDate) {
                            const now = new Date();
                            const diff = (expDate - now.getTime()) / (1000 * 60 * 60 * 24);
                            daysLeft = diff.toFixed(0);
                        }
                    } catch (error) {

                    }

                    return (<React.Fragment key={`${index}-item`}>
                        <tr key={`${index}-main`} className={`h-[40px] hover:bg-blue-50`}>
                            {/* Icon */}
                            <td className={`cursor-pointer rounded-lg text-center border-blue-500 ${item.id === activeId && ''}`} onClick={() => {
                                if (typeof setActive === 'function') {
                                    setActive(item.id === activeId ? '' : item.id);
                                }
                            }}>
                                <span className='text-gray-700'>
                                    {activeId === item.id && <IoIosArrowDown className='inline' />}
                                    {activeId !== item.id && <IoIosArrowForward className='inline' />}
                                </span>
                            </td>

                            {/* Name */}
                            <td className="whitespace-nowrap px-3">
                                <div className='flex flex-row items-center gap-3'>
                                    <button
                                        onClick={() => {
                                            if (typeof setActive === 'function') {
                                                setActive(item.id === activeId ? '' : item.id);
                                            }
                                        }}
                                        className={`font-medium hover:underline ${item.errors?.length > 0 ? 'text-orange-500' : activeId === item.id ? 'text-black' : item.expired ? 'text-gray-400' : 'text-blue-500'}`}>
                                        {item.label ?? item.user ?? '(unknown)'}</button>

                                    {item.status === 'online' && item.errors.length === 0 && <div className='group relative flex justify-center'>
                                        <FaRegCheckCircle className='text-green-600' />
                                        <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">Account is online</span>
                                    </div>}
                                    {item.status === 'online' && <div className='group relative flex justify-center'>
                                        <HiWrenchScrewdriver className='text-blue-500 cursor-pointer active:text-blue-700' size={16}
                                            onClick={() => {
                                                if (typeof setAction === 'function') {
                                                    setAction('disconnect', item);
                                                }
                                            }}
                                        />
                                        <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">Click to reconnect or fix error missing src and dst config</span>
                                    </div>}

                                    {item.errors?.length > 0 && <div className='group relative flex justify-center'>
                                        <PiWarningDiamondFill className='inline text-orange-500' />
                                        <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">{item?.errors.map((err: any, index: number) => { return <div key={`${index}-err`}><span>{err}</span><br /></div>; })}</span>
                                    </div>}

                                    {/*
                                        item.errors?.includes('missing secret configuration') && <div className='group relative flex justify-center'>
                                            <FaHammer className='text-blue-500 cursor-pointer active:text-blue-700'
                                                onClick={() => {
                                                    if (typeof setAction === 'function') {
                                                        setAction('recreate', item);
                                                    }
                                                }}
                                            />
                                            <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">Click to fix missing secret configuration error</span>
                                        </div>
                                    */}

                                    {item.expired && <div className='group relative flex justify-center'>
                                        <IoIosCloseCircle className='text-gray-500' size={18} />
                                        <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">expired</span>
                                    </div>}

                                </div>
                            </td>

                            {/* Remote URL */}
                            <td className="whitespace-nowrap px-3">

                                <div className='flex flex-row items-center gap-2'>

                                    <span className={`text-white font-medium text-xs px-2 py-[2px] rounded-md ${item.expired || item.status !== 'online' ? 'bg-gray-400' : 'bg-blue-500'}`}>
                                        <div className='flex flex-row items-center gap-1'>
                                            {item.to_port === '443' ? 'https://' : ''}{item.from_address}:{item.from_port}
                                            <div className={`group relative flex justify-center ${(item.expired || item.status !== 'online') && 'hidden'}`}>
                                                <IoCopy className='inline text-white cursor-pointer active:text-black' size={12} onClick={() => navigator.clipboard.writeText(`${item.to_port === '443' ? 'https://' : ''}${item.from_address}:${item.from_port}`)} />
                                                <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">Copy <span className='font-bold'>{item.to_port === '443' ? 'https://' : ''}{item.from_address}:{item.from_port}</span></span>
                                            </div>
                                        </div>
                                    </span>
                                    <FaArrowRight className='text-gray-300' size={14} />
                                    <span className={`text-white font-medium text-[10px] px-2 py-[2px] rounded-md ${item.expired || item.status !== 'online' ? 'bg-gray-400' : 'bg-green-500'}`}>{toPort}</span>

                                </div>
                            </td>

                            {/* Bandwidth */}
                            <td className="whitespace-nowrap text-sm px-1 text-center">{item.bandwidth}</td>

                            {/* Service */}
                            <td className="whitespace-nowrap text-sm px-1 text-center">{item.service}</td>

                            {/* Server */}
                            {/* <td className="whitespace-nowrap text-xs px-1 text-center">{item.server}</td> */}

                            {/* Status */}
                            <td className='text-center'><span className={`text-white rounded-md px-2 py-[1.5px] text-xs ${item.expired ? 'bg-red-500' : item.status !== 'online' ? 'bg-gray-500' : 'bg-green-500'}`}>{item.expired ? 'expired' : item.status ?? 'unknown'}</span></td>

                            {/* Subs */}
                            <td className="whitespace-nowrap px-1 text-center">
                                <span className='bg-gray-200 px-2 py-[1.5px] rounded-lg text-xs'>{item.subscription}</span>
                            </td>

                            {/* Action */}
                            <td className="whitespace-nowrap px-1 text-center rounded-lg">

                                <div className={`rounded-lg flex flex-row items-center justify-center ${actionId !== item.id && 'hidden'}`}>
                                    <Spinner />
                                </div>
                                <div className={`rounded-lg flex flex-row items-center justify-center gap-3 ${actionId === item.id && 'hidden'}`}>

                                    {/* {item.status === 'online' && <div className='group relative flex justify-center'>
                                    <IoMdCloseCircle className='text-orange-500 inline cursor-pointer active:text-black'
                                        onClick={actionId ? undefined : () => {
                                            setActionId(item.id);
                                            setAction('disconnect');
                                            setConfirmTitle(`Disconnect ${item.label ?? item.user}`);
                                            setConfirmMessage(`Are you sure you want to disconnect <b>${item.label ?? item.user}?</b><br />You will need to reconnect it again from your device.`);
                                            setConfirmOpen(true);
                                        }} />
                                    <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-orange-500 p-2 text-xs text-white group-hover:scale-100">Disconnect <span className='font-bold'>{item.label ?? item.name}</span></span>
                                </div>} */}

                                    <div className={`group relative flex justify-center ${item.subscription === 'demo' && 'invisible'}`}>
                                        <TiEdit className='text-green-500 inline cursor-pointer active:text-black h-[20px] w-[20px]'
                                            onClick={actionId ? undefined : () => {
                                                window.location.href = `/user/remote/${item.ref ?? ''}`;
                                            }} />
                                        {/* <span className="whitespace-nowrap absolute z-10 top-10 scale-0 transition-all rounded bg-red-500 p-2 text-xs text-white group-hover:scale-100">Delete <span className='font-bold'>{item.label ?? item.name}</span></span> */}
                                    </div>

                                    <div className={`group relative flex justify-center ${item.subscription !== 'demo' ? '' : 'invisible'}`}>
                                        <RiDeleteBin5Fill className='text-red-500 inline cursor-pointer active:text-black'
                                            onClick={actionId ? undefined : () => {
                                                if (typeof setAction === 'function') {
                                                    setAction('delete', item);
                                                }
                                            }} />
                                        {/* <span className="whitespace-nowrap absolute z-10 top-10 scale-0 transition-all rounded bg-red-500 p-2 text-xs text-white group-hover:scale-100">Delete <span className='font-bold'>{item.label ?? item.name}</span></span> */}
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr key={`${index}-sub`} className={`shadow-md rounded-lg ${activeId !== item.id && 'hidden'}`}>
                            <td colSpan={8} className='text-center text-sm rounded-lg'>

                                <div className='flex flex-row p-5 mb-5'>

                                    <div className='text-left flex-1 flex flex-col gap-2'>

                                        <div className='flex flex-row items-center'>
                                            <div className='w-[150px]'>Server:</div>
                                            <div className='w-[300px] px-2 py-1 border-[1.5px] border-gray-400 rounded-md flex flex-row items-center'>
                                                <b className='whitespace-nowrap grow'>{item.from_address}</b>
                                                <div className={`group relative flex justify-center ${item.expired && 'hidden'}`}>
                                                    <IoCopy className='inline text-gray-500 cursor-pointer hover:text-blue-500 active:text-black' size={15} onClick={() => navigator.clipboard.writeText(item.from_address)} />
                                                    <span className="whitespace-nowrap absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">Copy <span className='font-bold'>{item.from_address}</span></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='flex flex-row items-center'>

                                            <div className='w-[150px]'>User:</div>

                                            <div className='w-[300px] px-2 py-1 border-[1.5px] border-gray-400 rounded-md flex flex-row items-center'>
                                                <b className='whitespace-nowrap grow'>{item.user}</b>
                                                <div className={`group relative flex justify-center ${item.expired && 'hidden'}`}>
                                                    <IoCopy className='inline text-gray-500 cursor-pointer hover:text-blue-500 active:text-black' size={15} onClick={() => navigator.clipboard.writeText(item.user)} />
                                                    <span className="whitespace-nowrap absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">Copy <span className='font-bold'>{item.user}</span></span>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='flex flex-row items-center'>
                                            <div className='w-[150px]'>Password:</div>
                                            <div className='w-[300px] px-2 py-1 border-[1.5px] border-gray-400 rounded-md flex flex-row items-center'>
                                                <b className='whitespace-nowrap grow'>{item.password}</b>
                                                <div className={`group relative flex justify-center ${item.expired && 'hidden'}`}>
                                                    <IoCopy className='inline text-gray-500 cursor-pointer hover:text-blue-500 active:text-black' size={15} onClick={() => navigator.clipboard.writeText(item.password)} />
                                                    <span className="whitespace-nowrap absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">Copy <span className='font-bold'>{item.password}</span></span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={`flex flex-row items-center ${!item.uptime && 'hidden'}`}>
                                            <div className='w-[150px]'>Uptime:</div>
                                            <div className='w-[300px] px-2 py-1 border-[1.5px] border-gray-400 rounded-md whitespace-nowrap font-bold'>{item.uptime}</div>
                                        </div>
                                        <div className={`flex flex-row items-center ${!item.remote_ip && 'hidden'}`}>
                                            <div className='w-[150px]'>Remote IP:</div>
                                            <div className='w-[300px] px-2 py-1 border-[1.5px] border-gray-400 rounded-md whitespace-nowrap font-bold'>{item.remote_ip}</div>
                                        </div>
                                        <div className={`flex flex-row items-center ${!expiration && 'hidden'}`}>
                                            <div className='w-[150px]'>Expires On:</div>
                                            <div className='w-[300px] px-2 py-1 border-[1.5px] border-gray-400 rounded-md whitespace-nowrap font-bold'>{expiration} ({`${daysLeft} day${daysLeft === '1' ? '' : 's'}`})</div>
                                        </div>
                                    </div>

                                    <div className='pl-5 text-left flex-1 flex flex-col gap-2'>
                                        <div className='font-medium text-md'>Tutorials:</div>
                                        <ul className='list-disc ml-5 mt-2 flex flex-col gap-2'>
                                            <li>
                                                <a href={`/tutorials/how-to-connect-${item.service}-client`} rel="noreferrer" target='_blank' className='underline text-blue-500 font-medium'>How to install {item.service} client on mikoritk</a>
                                            </li>

                                            <li className={`${(item.service !== 'ovpn' || !item.ref) && 'hidden'}`}>
                                                <a href={`/tutorials/how-to-install-${item.service}-client-on-adopisoft`} rel="noreferrer" target='_blank' className='underline text-blue-500 font-medium'>How to install ovpn client on Adopisoft Orange PI/Raspberry PI</a>
                                                <div className='ml-3 mt-2 px-2 flex flex-col gap-1'>
                                                    <div>Installer file:</div>
                                                    <div className='flex flex-row items-center gap-2'>
                                                        <code className='bg-gray-700 text-white p-2 tracking-wide text-xs rounded-md flex flex-row items-center gap-2'>
                                                            <span className='text-[12px]'>{process.env.REACT_APP_ENDPOINT_DL}/{item.ref ?? ''}/ovpn.sh</span>
                                                            <div className={`group relative flex justify-center ${item.expired && 'hidden'}`}>
                                                                <IoCopy className='inline text-white cursor-pointer hover:text-blue-500 active:text-black' size={12} onClick={() => navigator.clipboard.writeText(`${process.env.REACT_APP_ENDPOINT_DL}/${item.ref ?? ''}/ovpn.sh`)} />
                                                                <span className="whitespace-nowrap absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">Copy <span className='font-bold'>{process.env.REACT_APP_ENDPOINT_DL}/{item.ref ?? ''}/ovpn.sh</span></span>
                                                            </div>
                                                        </code>
                                                        <div className={`group relative flex justify-center`}>
                                                            <FaInfoCircle className='inline text-orange-500' size={15} />
                                                            <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 min-w-[200px]">Do not share this link to any one. This link contains user sensitive information</span>
                                                        </div>
                                                    </div>

                                                </div>

                                            </li>

                                            <li className={`${(item.service !== 'l2tp' || !item.ref) && 'hidden'}`}>
                                                <a /*href={`/tutorials/how-to-install-${item.service}-client-on-adopisoft-opi`}*/ rel="noreferrer" target='_blank' className='underline text-gray-500 font-medium'>How to install l2tp client on Adopisoft Orange PI/Ubuntu Server</a>
                                                <div className='ml-3 mt-2 px-2 flex flex-col gap-1'>
                                                    <div>Installer file:</div>
                                                    <div className='flex flex-row items-center gap-2'>
                                                        <code className='bg-gray-700 text-white p-2 tracking-wide text-xs rounded-md flex flex-row items-center gap-2'>
                                                            <span className='text-[12px]'>{process.env.REACT_APP_ENDPOINT_DL}/{item.ref ?? ''}/l2tp.sh</span>
                                                            <div className={`group relative flex justify-center ${item.expired && 'hidden'}`}>
                                                                <IoCopy className='inline text-white cursor-pointer hover:text-blue-500 active:text-black' size={12} onClick={() => navigator.clipboard.writeText(`${process.env.REACT_APP_ENDPOINT_DL}/${item.ref ?? ''}/l2tp.sh`)} />
                                                                <span className="whitespace-nowrap absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">Copy <span className='font-bold'>{process.env.REACT_APP_ENDPOINT_DL}/{item.ref ?? ''}/l2tp.sh</span></span>
                                                            </div>
                                                        </code>
                                                        <div className={`group relative flex justify-center`}>
                                                            <FaInfoCircle className='inline text-orange-500' size={15} />
                                                            <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">Do not share this link to any one. This link contains user sensitive information</span>
                                                        </div>
                                                    </div>



                                                </div>

                                            </li>

                                        </ul>
                                    </div>

                                </div>

                                {/* <div className='py-5 px-5 flex flex-row items-center gap-5'>
                                    <div className='grow'></div>

                                    {item.uptime &&
                                        <div className='text-xs px-2 py-1 border-[1.5px] border-gray-500 rounded-md flex flex-row items-center gap-2'>
                                            <span>Up Time:</span><b>{item.uptime}</b>
                                        </div>}

                                    {item.remote_ip &&
                                        <div className='text-xs px-2 py-1 border-[1.5px] border-gray-500 rounded-md flex flex-row items-center gap-2'>
                                            <span>Remote IP:</span><b>{item.remote_ip}</b>
                                        </div>}

                                    {expiration &&
                                        <div className='text-xs px-2 py-1 border-[1.5px] border-gray-500 rounded-md flex flex-row items-center gap-1'>
                                            <span>Expired On:</span><b>{expiration} ({`${daysLeft} day${daysLeft === '1' ? '' : 's'}`})</b>
                                        </div>}

                                    <div className='text-xs px-2 py-1 border-[1.5px] border-gray-500 rounded-md flex flex-row items-center gap-1'>
                                        <span>Server:</span><b>{item.from_address}</b>
                                        <div className={`group relative flex justify-center ${item.expired && 'hidden'}`}>
                                            <IoCopy className='inline text-gray-500 cursor-pointer hover:text-blue-500 active:text-black' size={15} onClick={() => navigator.clipboard.writeText(item.from_address)} />
                                            <span className="whitespace-nowrap absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">Copy <span className='font-bold'>{item.from_address}</span></span>
                                        </div>
                                    </div>

                                    <div className='text-xs px-2 py-1 border-[1.5px] border-gray-500 rounded-md flex flex-row items-center gap-1'>
                                        <span>user:</span><b className='whitespace-nowrap'>{item.user}</b>
                                        <div className={`group relative flex justify-center ${item.expired && 'hidden'}`}>
                                            <IoCopy className='inline text-gray-500 cursor-pointer hover:text-blue-500 active:text-black' size={15} onClick={() => navigator.clipboard.writeText(item.user)} />
                                            <span className="whitespace-nowrap absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">Copy <span className='font-bold'>{item.user}</span></span>
                                        </div>
                                    </div>

                                    <div className='text-xs px-2 py-1 border-[1.5px] border-gray-500 rounded-md flex flex-row items-center gap-1'>
                                        <span>password:</span><b className='whitespace-nowrap'>{item.password}</b>
                                        <div className={`group relative flex justify-center ${item.expired && 'hidden'}`}>
                                            <IoCopy className='inline text-gray-500 cursor-pointer hover:text-blue-500 active:text-black' size={15} onClick={() => navigator.clipboard.writeText(item.password)} />
                                            <span className="whitespace-nowrap absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">Copy <span className='font-bold'>{item.password}</span></span>
                                        </div>
                                    </div>

                                </div> */}

                                {/* <div className='text-left ml-5 mb-10'>
                                    <div className='font-bold text-md'>Tutorials on how to use:</div>
                                    <ul className='ml-5 mt-2 flex flex-col gap-2 text-md underline text-green-500 font-medium'>
                                        <li>
                                            <a href={`/tutorials/how-to-connect-${item.service}-client`} rel="noreferrer" target='_blank' className=''>How to install {item.service} client on mikoritk</a>
                                        </li>
                                    </ul>
                                </div> */}

                            </td>
                        </tr>
                    </React.Fragment>)
                })}

            </tbody>
        </table>
    </div>

}