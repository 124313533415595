import { useEffect, useState } from "react";
import Loader from "../../../../components/loader";
import UserNav from "../../components/userNav";
import UserHeader from "../../components/userHeader";
import api from "../../../../utils/api";
import { errorMessage } from "../../../../utils/utils";
import { useParams } from "react-router-dom";
import { IoMdArrowBack, IoMdRefreshCircle } from "react-icons/io";
import Spinner from "../../../../components/spinner";
import { Bounce, toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { FaEdit, FaQuestion, FaSave } from "react-icons/fa";
import { IoSaveSharp } from "react-icons/io5";
import { AiFillCloseSquare } from "react-icons/ai";
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from "@headlessui/react";
import gcash from '../../../../assets/gcash-logo.svg';
import maya from '../../../../assets/paymaya-logo.svg';
import shopee from '../../../../assets/shopeepay-logo-2.svg';
import grab from '../../../../assets/grabpay-logo.svg';

const monthly = 40;
const yearly = 360;

const ports = [
    { value: '8291', label: 'Winbox (8291)' },
    { value: '80', label: 'HTTP (80)' },
    { value: '443', label: 'HTTPS (443)' },
    { value: '22', label: 'SSH (22)' },
    { value: 'custom', label: 'Custom' },
];

export default function RemoteItem() {

    useEffect(() => {

        if (!window.localStorage.token) {
            window.location.replace('/signin');
            return;
        }

        setPageLoading(false);

        getData();

    }, []);


    const { id } = useParams();
    const [pageLoading, setPageLoading] = useState(true);
    const [nav, setNav] = useState(false);

    const [dataLoading, setDataLoading] = useState(true);
    const [dataError, setDataError] = useState('');
    const [data, setData] = useState<any>();

    const [serverLoading, setServerLoading] = useState(true);
    const [servers, setServers] = useState<any>([]);
    const [services, setServices] = useState<any>([]);

    const [label, setLabel] = useState('');
    const [service, setService] = useState('');
    const [server, setServer] = useState('');
    const [app_port, setAppPort] = useState('');
    const [custom, setCustom] = useState('');
    const [protocol, setProtocol] = useState('');

    const [update, setUpdate] = useState('');
    const [updating, setUpdating] = useState(false);

    const [openDialog, setOpenDialog] = useState(false);
    const [quantity, setQuantity] = useState('1');
    const [unit, setUnit] = useState('year');
    const [expiration, setExpiration] = useState<any>();
    const [payment, setPayment] = useState('');
    const [subsReq, setSubsReq] = useState(false);
    const [paymentReq, setPaymentReq] = useState(false);
    const [renewError, setRenewError] = useState('');
    const [renewLoading, setRenewLoading] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState(false);

    async function getData() {

        setDataError('');
        setDataLoading(true);
        try {

            const res = await api.get(`/remote/${id}`);
            const json = await res.json();
            if (res.ok) {
                setData(json);
                setLabel(json.label);
                setServer(json.server);
                setService(json.service);
                setAppPort(json.app_port);
                setCustom(json.custom);
                setProtocol(json.protocol);
                getServers(json.server);
            } else {
                setServerLoading(false);
                setDataError(errorMessage(json));
            }
        } catch (error) {
            setServerLoading(false);
            setDataError(errorMessage(error));
        }

        setDataLoading(false);
    }

    async function getServers(name?: string) {
        try {
            const res = await api.get(`/remote/server`);
            const json = await res.json();
            if (res.ok) {
                setServers(json);
                if (json.length > 0) {
                    const find = json.find((item: any) => item.name === name);
                    if (find) {
                        setServices(find.services);
                    }
                }
            } else {
                toast.error(errorMessage(json));
            }
        } catch (err) {
            toast.error(errorMessage(err));
        }

        setServerLoading(false);
    }

    async function updateData() {
        if (!update) return;

        switch (update) {
            case 'label':
                if (data.label === label) return;
                break;
            case 'service':
                if (data.service === service) return;
                break;
            case 'app_port':
                if (app_port === 'custom') {
                    if (data.protocol === protocol && data.custom === custom) return;
                } else {
                    if (data.app_port === app_port) return;
                }
                break;
        }

        setUpdating(true);

        try {
            const res = await api.patch(`/remote/${id}`, { update, label, server, service, app_port, custom, protocol });
            const json = await res.json();
            if (res.ok) {
                toast.success(json.message);

                switch (update) {
                    case 'label':
                        data.label = label;
                        break;
                    case 'service':
                        data.service = service;
                        break;
                    case 'app_port':
                        data.app_port = app_port;
                        data.protocol = protocol;
                        data.custom = custom;
                        break;
                }

                startEdit('');

            } else {
                toast.error(errorMessage(json));
            }

        } catch (err) {
            toast.error(errorMessage(err));
        }

        setUpdating(false);
    }


    function getDate() {

        const date = data?.date_created ? moment(data.date_created).format('MMMM Do YYYY') : undefined;
        return date;
    }

    function getExpiration() {

        const date = data?.date_expiration ? moment(data.date_expiration).format('MMMM Do YYYY') : undefined;
        return date;
    }

    function getDaysLeft() {

        let daysLeft;

        try {
            const expDate = Date.parse(data.date_expiration?.toString());

            if (expDate) {
                const now = new Date();
                const diff = (expDate - now.getTime()) / (1000 * 60 * 60 * 24);
                daysLeft = diff.toFixed(0);
            }
        } catch (error) {

        }

        return daysLeft;
    }

    function getServerLabel() {
        let label = '';
        const find = servers.find((item: any) => item.name === data?.server);

        if (find) {
            label = find.label;
        }

        return label;
    }

    function getAppPort() {

        let label = '';

        if (data?.app_port === 'custom') {
            label = data?.protocol?.toUpperCase() + ':' + data?.custom;
        } else {
            const find = ports.find((item: any) => item.value === data?.app_port);
            if (find) {
                label = find.label;
            }
        }

        return label;
    }

    function startEdit(name: string) {
        if (updating) return;

        setLabel(data?.label);
        setServer(data?.server);
        setService(data?.service);
        setAppPort(data?.app_port);
        setCustom(data?.custom);
        setProtocol(data?.protocol);
        setUpdate(name);
    }

    function getEstimateExpirationDate(quantity: string, unit: string) {
        // var date = data?.date_expiration ? moment(data?.date_expiration).toDate() : new Date();

        const today = new Date();

        var date = data?.date_expiration ? moment(data.date_expiration).toDate() : today;

        var exp = new Date(date);

        try {
            const expDate = Date.parse(data?.date_expiration?.toString());
            if (expDate) {
                const now = new Date();
                const days = (expDate - now.getTime()) / (1000 * 60 * 60 * 24);
                if (days <= 0) {
                    exp = today;
                }
            }

        } catch (error) {
            console.log('Error date expiration:', error);
        }



        switch (unit) {
            case 'month':
                exp.setMonth(date.getMonth() + parseInt(quantity));
                break;
            case 'year':
                exp.setFullYear(date.getFullYear() + parseInt(quantity));
                break;
        }

        setExpiration(moment(exp).format('MMMM Do YYYY'));
    }

    function total(): any {

        const json: any = { total: '0', fee: '0', vat: '0', subtotal: '0' };

        let fee = 0;
        let vat = 0;
        let subtotal = 0;
        //gcash 2.3%
        //maya 1.8%
        //shopeepay 2.0%
        //grabpay 2.0%

        const multiplier = isNaN(parseInt(quantity)) ? 0 : parseInt(quantity);

        switch (unit) {
            case 'month':
                subtotal = monthly * multiplier;
                break;
            case 'year':
                subtotal = yearly * multiplier;
                break;
        }

        switch (payment) {
            case 'gcash':
                fee = subtotal * 0.023;
                break;
            case 'maya':
                fee = subtotal * 0.018;
                break;
            case 'shopeepay':
                fee = subtotal * 0.02;
                break;
            case 'grabpay':
                fee = subtotal * 0.02;
                break;
        }


        json.subtotal = Math.ceil(subtotal).toLocaleString();
        json.fee = Math.ceil(fee).toLocaleString();
        json.vat = Math.ceil(vat).toLocaleString();

        json.total = Math.ceil(subtotal + fee + vat).toLocaleString();

        return json;

    }

    function submit() {

        if (!quantity || !unit) {
            setSubsReq(true);
            setRenewError('Please add subscription plan');
            return;
        } else {
            setSubsReq(false);
        }

        if (!payment) {
            setPaymentReq(true);
            setRenewError('Please select you payment method');
            return;
        } else {
            setPaymentReq(false);
        }

        setRenewError('');
        setConfirmDialog(true);
    }

    async function renew() {
        setRenewLoading(true);

        try {
            const res = await api.post(`/remote/renew/${id}`, { quantity: quantity, unit: unit, payment: payment });
            const json = await res.json();
            if (res.ok) {
                window.location.replace(`/user/transaction/${json.id}?u=1`);
                return;
            } else {
                setRenewError(errorMessage(json));
            }

        } catch (error) {
            setRenewError(errorMessage(error));
        }

        setRenewLoading(false);
    }

    if (pageLoading) {
        return (<div className='w-screen h-screen text-center flex flex-col bg-white justify-center'><Loader /></div>)
    }

    return (
        <>
            <div className='flex flex-row w-full'>
                <div className="hidden xl:block"><UserNav nav='remote' /></div>
                <main className='grow w-screen flex flex-col'>

                    <UserHeader title={`My Remote Access > ${label}`} setNav={() => { setNav(!nav) }} />

                    <section className="py-5 sm:p-5">
                        {/* <IoMdArrowBack className="cursor-pointer h-[30px] w-[30px]" onClick={() => window.history.back()} /> */}

                        <div className={`w-full px-5 ${!dataLoading && 'hidden'}`}>
                            <div className='mx-auto text-center'><Spinner className='w-8 h-8 inline text-blue-500 fill-gray-400' /></div>
                        </div>

                        <div className={`w-full bg-white rounded-xl p-5 flex flex-col ${(dataLoading || !data || dataError) && 'hidden'}`}>

                            <div className="flex flex-row items-center">

                                <label htmlFor="label" className="block text-sm leading-6 text-gray-900 w-[100px]">Label</label>

                                <div className={`text-blue-500 ${update === 'label' && 'hidden'}`}>{data?.label}</div>

                                <div className={`${update !== 'label' && 'hidden'}`}>
                                    <input
                                        name="label"
                                        type="text"
                                        required={true}
                                        value={label}
                                        disabled={dataLoading || updating}
                                        // placeholder="Just a label for your device"
                                        className="w-full md:w-[300px] text-blue-500 no-ring border-0 border-b-[1px] border-gray-400"
                                        onChange={(e) => setLabel(e.target.value)}
                                    />
                                </div>

                                <FaEdit className={`ml-3 text-blue-500 w-[11px] h-[11px] cursor-pointer ${update === 'label' && 'hidden'}`} onClick={() => startEdit('label')} />

                                <div className={`flex flex-row items-center ${(update !== 'label' || updating) && 'hidden'}`}>
                                    <AiFillCloseSquare className={`ml-3 text-gray-500 w-[18px] h-[18px] cursor-pointer`} onClick={() => startEdit('')} />
                                    <FaSave className={`ml-3 text-green-500 w-[16px] h-[16px] cursor-pointer`} onClick={updateData} />
                                </div>

                                <Spinner className={`ml-3 fill-blue-500 text-blue-100 ${(update !== 'label' || !updating) && 'hidden'}`} />

                            </div>

                            <div className={`mt-1 flex flex-row items-center`}>
                                <label htmlFor="server" className="block text-sm leading-6 text-gray-900 w-[100px]">Server</label>
                                {serverLoading && <Spinner className='ml-2' />}
                                <div className={`${update === 'server' && 'hidden'}`}>{getServerLabel()}</div>
                                <div className={`relative ${update !== 'server' && 'hidden'}`}>
                                    {serverLoading && <Spinner className='absolute ml-2 mt-[5px]' />}
                                    <select
                                        name='server'
                                        value={server}
                                        required={true}
                                        disabled={dataLoading || serverLoading || updating}
                                        className={`no-ring w-full md:w-[300px] border-0 border-b-[1px] border-gray-400`}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (value) {
                                                const find = servers.find((item: any) => item.name === value);
                                                if (find) {
                                                    setServices(find.services);
                                                }
                                            } else {
                                                setServices([])

                                            }

                                            setServer(value);
                                        }}>
                                        {!serverLoading && <option value=''>Please select...</option>}
                                        {
                                            servers.map((item: any, index: number) => {
                                                return <option key={`${index}-server`} value={item.name} disabled={!item.enabled}>{item.label ?? item.name}{!item.enabled && ' (unavailable)'}</option>
                                            })
                                        }

                                    </select>
                                </div>

                                <div className={`flex flex-row items-center ${serverLoading && 'hidden'}`}>
                                    <FaEdit className={`ml-3 text-gray-500 w-[11px] h-[11px] ${update === 'server' && 'hidden'}`} onClick={undefined /*() => startEdit('server')*/} />
                                    <div className={`flex flex-row items-center ${(update !== 'server' || updating) && 'hidden'}`}>
                                        <AiFillCloseSquare className={`ml-3 text-gray-500 w-[18px] h-[18px]`} onClick={undefined /*() => startEdit('')*/} />
                                        <FaSave className={`ml-3 text-gray-500 w-[16px] h-[16px]`} onClick={undefined /*updateData*/} />
                                    </div>
                                    <Spinner className={`ml-3 fill-blue-500 text-blue-100 ${(update !== 'server' || !updating) && 'hidden'}`} />
                                </div>
                            </div>

                            <div className="mt-1 flex flex-row items-center">
                                <label htmlFor="service" className="block text-sm leading-6 text-gray-900 w-[100px]">Service</label>
                                <div className={`${update === 'service' && 'hidden'}`}>{data?.service?.toUpperCase()}</div>
                                <select
                                    name='service'
                                    value={service}
                                    style={{ width: "200px" }}
                                    required={true}
                                    disabled={dataLoading || !server || updating}
                                    className={`no-ring border-0 border-b-[1px] border-gray-400 ${update !== 'service' && 'hidden'}`}
                                    onChange={(e) => setService(e.target.value)}>
                                    <option value=''>Please select...</option>
                                    {
                                        services.map((item: any, index: number) => {
                                            return <option key={`${index}-service`} value={item}>{item?.toUpperCase()}</option>
                                        })
                                    }
                                </select>
                                <FaEdit className={`ml-3 text-blue-500 w-[11px] h-[11px] cursor-pointer ${update === 'service' && 'hidden'}`} onClick={() => startEdit('service')} />
                                <div className={`flex flex-row items-center ${(update !== 'service' || updating) && 'hidden'}`}>
                                    <AiFillCloseSquare className={`ml-3 text-gray-500 w-[18px] h-[18px] cursor-pointer`} onClick={() => startEdit('')} />
                                    <FaSave className={`ml-3 text-green-500 w-[16px] h-[16px] cursor-pointer`} onClick={updateData} />
                                </div>
                                <Spinner className={`ml-3 fill-blue-500 text-blue-100 ${(update !== 'service' || !updating) && 'hidden'}`} />
                            </div>

                            <div className="mt-1 flex flex-row items-center">
                                <label htmlFor="application" className="block text-sm leading-6 text-gray-900 w-[100px]">Application</label>
                                <div className={`${update === 'app_port' && 'hidden'}`}>{getAppPort()}</div>
                                <select
                                    name='application'
                                    value={app_port}
                                    style={{ width: "200px" }}
                                    required={true}
                                    disabled={dataLoading || !server || updating}
                                    className={`no-ring border-0 border-b-[1px] border-gray-400 ${update !== 'app_port' && 'hidden'}`}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (value === 'custom') {
                                            if (!protocol) {
                                                setProtocol('tcp');
                                            }
                                        } else {
                                            setCustom('');
                                            setProtocol('');
                                        }
                                        setAppPort(value);

                                    }}>
                                    <option value=''>Please select...</option>
                                    {
                                        ports.map((item: any, index: number) => {
                                            return <option key={`${index}-port`} value={item.value}>{item.label}</option>
                                        })
                                    }
                                </select>
                                <FaEdit className={`ml-3 text-blue-500 w-[11px] h-[11px] cursor-pointer ${update === 'app_port' && 'hidden'}`} onClick={() => startEdit('app_port')} />
                                <div className={`flex flex-row items-center ${(update !== 'app_port' || updating) && 'hidden'}`}>
                                    <AiFillCloseSquare className={`ml-3 text-gray-500 w-[18px] h-[18px] cursor-pointer`} onClick={() => startEdit('')} />
                                    <FaSave className={`ml-3 text-green-500 w-[16px] h-[16px] cursor-pointer`} onClick={updateData} />
                                </div>
                                <Spinner className={`ml-3 fill-blue-500 text-blue-100 ${(update !== 'app_port' || !updating) && 'hidden'}`} />

                            </div>

                            <div className={`mt-1 mb-2 flex flex-col gap-3 ${(app_port !== 'custom' || update !== 'app_port') && 'hidden'}`}>
                                <div className="flex flex-row items-center">
                                    <label htmlFor="custom" className="block text-sm leading-6 text-gray-900 w-[100px]">Port</label>
                                    <input
                                        name="custom"
                                        type="text"
                                        required={app_port === 'custom' ? true : false}
                                        value={custom}
                                        disabled={dataLoading || !server || updating}
                                        style={{ width: "200px" }}
                                        maxLength={5}
                                        placeholder="0 to 65535"
                                        className="no-ring border-0 border-b-[1px] border-gray-400 placeholder:text-sm"
                                        onChange={(e) => {
                                            const re = /^[0-9\b]+$/;
                                            if (e.target.value === '' || re.test(e.target.value)) {
                                                setCustom(e.target.value);
                                            }
                                        }}
                                    />
                                </div>

                                <div className="flex flex-row items-center">
                                    <label htmlFor="protocol" className="block text-sm leading-6 text-gray-900 w-[100px]">Protocol</label>
                                    <select
                                        name='protocol'
                                        required={app_port === 'custom' ? true : false}
                                        value={protocol}
                                        disabled={dataLoading || !server || updating}
                                        style={{ width: "200px" }}
                                        className={`no-ring border-0 border-b-[1px] border-gray-400`}
                                        onChange={(e) => setProtocol(e.target.value)}>
                                        <option value=''>Please select...</option>
                                        <option value='tcp'>TCP</option>
                                        <option value='udp'>UDP</option>
                                    </select>
                                </div>
                            </div>

                            <div>
                                <div className="mt-1 flex flex-row items-center">
                                    <label htmlFor="label" className="block text-sm leading-6 text-gray-900 w-[100px]">Created On:</label>
                                    <div>{getDate()}</div>
                                </div>

                                <div className="mt-1 flex flex-row items-center">
                                    <label htmlFor="label" className="block text-sm leading-6 text-gray-900 w-[100px]">Expired On:</label>
                                    <div>{getExpiration()}</div>
                                </div>

                                <div className="mt-1 flex flex-row items-center">
                                    <label htmlFor="label" className="block text-sm leading-6 text-gray-900 w-[100px]">Day(s) Left:</label>
                                    <div>{getDaysLeft()}</div>
                                </div>

                                <div className="mt-1 flex flex-row items-center">
                                    <label htmlFor="label" className="block text-sm leading-6 text-gray-900 w-[100px]">Speed:</label>
                                    <div>{data?.bandwidth}</div>
                                </div>

                                <div className="mt-1 flex flex-row items-center">
                                    <label htmlFor="label" className="block text-sm leading-6 text-gray-900 w-[100px]">Status:</label>
                                    <span className={`px-2 py-[1.5px] rounded-md text-white font-bold text-xs ${data?.status === 'online' ? 'bg-green-500' : 'bg-gray-500'}`}>{data?.status}</span>
                                    <span className={`ml-1 px-2 py-[1.5px] rounded-md text-white font-bold text-xs bg-red-500 ${!data?.expired && 'hidden'}`}>expired</span>
                                </div>

                                <div className={`${data?.status !== 'online' && 'hidden'}`}>
                                    <div className="mt-1 flex flex-row items-center">
                                        <label htmlFor="label" className="block text-sm leading-6 text-gray-900 w-[100px]">Up Time:</label>
                                        <div>{data?.uptime}</div>
                                    </div>

                                    <div className="mt-1 flex flex-row items-center">
                                        <label htmlFor="label" className="block text-sm leading-6 text-gray-900 w-[100px]">Remote IP:</label>
                                        <div>{data?.remote_ip}</div>
                                    </div>

                                </div>

                                {/* <div className="mt-1 flex flex-row items-center">
                                    <label htmlFor="label" className="block text-sm leading-6 text-gray-900 w-[100px]">Connect To:</label>
                                    <div className="grow text-blue-500">{servers.map((item: any) => item.name === data?.server)?.domain}</div>
                                </div> */}

                                <div className="mt-1 flex flex-row items-center">
                                    <label htmlFor="label" className="block text-sm leading-6 text-gray-900 w-[100px]">Connect To:</label>
                                    <div className="text-blue-500">{data?.from_address}</div>
                                </div>

                                <div className="mt-1 flex flex-row items-center">
                                    <label htmlFor="label" className="block text-sm leading-6 text-gray-900 w-[100px]">User:</label>
                                    <div className="text-blue-500">{data?.user}</div>
                                </div>

                                <div className="mt-1 flex flex-row items-center">
                                    <label htmlFor="label" className="block text-sm leading-6 text-gray-900 w-[100px]">Password:</label>
                                    <div className="text-blue-500">{data?.password}</div>
                                </div>

                                <div className="mt-1 flex flex-row items-center">
                                    <label htmlFor="label" className="block text-sm leading-6 text-gray-900 w-[100px]">Remote Port:</label>
                                    <div className="text-blue-500">{data?.port}</div>

                                    <div className='group relative flex justify-center'>
                                        <IoMdRefreshCircle className={`ml-2 text-gray-500 w-[18px] h-[18px]`} onClick={() => { }} />
                                        <span className="whitespace-nowrap absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100">Get a new remote port (Unavailable)</span>
                                    </div>
                                </div>

                                <div className="mt-1 flex flex-row items-center">
                                    <label htmlFor="label" className="block text-sm leading-6 text-gray-900 w-[100px]">Remote Url:</label>
                                    <div className="text-blue-500">{data?.from_address}:{data?.port}</div>
                                </div>

                                <div className="mt-5 text-xs">Having troubles connecting? <a href={`/tutorials/how-to-connect-${data?.service}-client`} rel="noreferrer" target='_blank' className='text-blue-500 font-medium'>click here</a> for tutorials on how to connect.</div>

                            </div>

                            <div className="mt-5 flex flex-row gap-2">
                                <button onClick={() => window.history.back()} className={`border border-gray-300 rounded-md bg-white px-5 py-1 shadow-md`}>Back</button>
                                <div className="grow"></div>
                                <button onClick={() => {
                                    getEstimateExpirationDate(quantity, unit);
                                    setOpenDialog(true);
                                }} className={`rounded-md text-white bg-green-500 px-5 py-1 shadow-md ${data?.subscription !== 'paid' && 'hidden'}`}>Renew</button>
                                {/* <div className={`flex flex-row gap-2 ${!update && 'hidden'}`}>
                                    <button onClick={() => startEdit('')} className="border border-gray-400 rounded-md bg-white px-5 py-1 shadow-md">Cancel</button>
                                    <button onClick={() => { }} className="rounded-md text-white bg-green-500 px-5 py-1 shadow-md">Save</button>
                                </div> */}

                            </div>

                        </div>

                        <div className={`mt-5 w-full bg-white rounded-xl py-5 px-3 sm:px-5 flex flex-col ${(dataLoading || !data || dataError) && 'hidden'}`}>
                            <div className="font-semibold mb-3">Transaction History</div>

                            <div className="hidden md:block"><Large items={data?.references ?? []} /></div>
                            <div className="md:hidden w-full"><Small items={data?.references ?? []} /></div>

                        </div>

                        <div className={`w-full text-center px-5 ${(dataLoading || !dataError) && 'hidden'}`}>
                            <div className='text-red-500 italic text-sm'><div dangerouslySetInnerHTML={{ __html: dataError }} /></div>
                        </div>

                    </section>
                </main>
            </div>

            <Dialog open={openDialog} onClose={() => {
                if (!renewLoading) {
                    setOpenDialog(false);
                }
            }} className="relative z-10">
                <DialogBackdrop transition className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in" />
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full justify-center sm:p-4 text-center items-center">
                        <DialogPanel
                            transition
                            className="w-full sm:w-[640px] py-8 px-5 sm:px-10 relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                        >

                            <div className="mb-5">
                                <div className="font-medium mb-2">Expiration Date:</div>
                                <div className="flex flex-row text-sm ml-3"><span className="flex w-[80px]">Current:</span><span>{getExpiration()}</span></div>
                                <div className="flex flex-row text-sm ml-3"><span className="flex w-[80px]">New:</span><span>{expiration}</span></div>

                            </div>

                            <hr className='border-[1px] my-5' />

                            <div className="mb-5">

                                <div className="font-medium">Add Subscription Plan</div>
                                <div className={`text-red-500 italic text-xs ${!subsReq && 'hidden'}`}>* Please add subscription plan</div>

                                <div className='ml-5 mt-3 flex flex-row items-center gap-3'>
                                    <input
                                        type="number"
                                        min={1}
                                        value={quantity}
                                        style={{ width: "100px", height: '40px' }}
                                        disabled={renewLoading}
                                        aria-describedby="helper-text-explanation"
                                        className="px-3 py-1 border-[1px] border-gray-300 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-50"
                                        onChange={(e) => {
                                            if (e.target.value === '0') return;
                                            setQuantity(e.target.value);
                                            getEstimateExpirationDate(e.target.value, unit);
                                            if (unit) {
                                                setPaymentReq(false);
                                            }
                                        }}
                                    />
                                    <select
                                        value={unit}
                                        style={{ width: "150px", height: '40px' }}
                                        disabled={renewLoading}
                                        className={`px-2 py-1 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 disabled:bg-gray-50`}
                                        onChange={(e) => {
                                            setUnit(e.target.value);
                                            getEstimateExpirationDate(quantity, e.target.value);
                                            if (quantity) {
                                                setPaymentReq(false);
                                            }
                                        }}>
                                        <option disabled={true}>Please select...</option>
                                        <option value='month'>Month{parseInt(quantity) > 1 ? 's' : ''}</option>
                                        <option value='year'>Year{parseInt(quantity) > 1 ? 's' : ''}</option>
                                    </select>
                                </div>

                            </div>

                            <hr className='border-[1px] my-5' />

                            <div className="mb-5">
                                <div className='mb-3'>
                                    <div className='font-medium'>Select your payment method:</div>
                                    <div className={`text-red-500 italic text-xs ${!paymentReq && 'hidden'}`}>* Please select your payment method</div>
                                    <div className={`flex flex-row items-center gap-2 ${!payment && 'hidden'}`}>
                                        <div className='text-xs'>You have selected <span className='font-bold'>{payment.toUpperCase()}</span> as your payment method</div>
                                    </div>
                                </div>

                                <div className='flex flex-row gap-3'>
                                    <button disabled={renewLoading} type="button" onClick={() => {
                                        setPayment(payment === 'gcash' ? '' : 'gcash');
                                        setPaymentReq(false);
                                    }}>
                                        <div className='group relative flex justify-center'>
                                            <img src={gcash} alt="GCASH" className={`h-[35px] rounded-md border-[2px] ${payment === 'gcash' ? 'border-green-500' : 'border-transparent'}`} />
                                            <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 whitespace-nowrap">GCash</span>
                                        </div>
                                    </button>
                                    <button disabled={renewLoading} type="button" onClick={() => {
                                        setPayment(payment === 'maya' ? '' : 'maya');
                                        setPaymentReq(false);
                                    }}>
                                        <div className='group relative flex justify-center'>
                                            <img src={maya} alt="MAYA" className={`h-[40px] rounded-md border-[2px] ${payment === 'maya' ? 'border-green-500' : 'border-transparent'}`} />
                                            <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 whitespace-nowrap">Maya</span>
                                        </div>
                                    </button>
                                    <button disabled={renewLoading} type="button" onClick={() => {
                                        setPayment(payment === 'shopeepay' ? '' : 'shopeepay');
                                        setPaymentReq(false);
                                    }}>
                                        <div className='group relative flex justify-center'>
                                            <img src={shopee} alt="SHOPEEPAY" className={`h-[35px] rounded-md border-[2px] ${payment === 'shopeepay' ? 'border-green-500' : 'border-transparent'}`} />
                                            <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 whitespace-nowrap">ShopeePay</span>
                                        </div>
                                    </button>
                                    <button disabled={true} type="button" onClick={() => {
                                        setPayment(payment === 'grabpay' ? '' : 'grabpay');
                                        setPaymentReq(false);
                                    }}>
                                        <div className='group relative flex justify-center'>
                                            <img src={grab} alt="GRABPAY" className={`grayscale h-[35px] rounded-md border-[2px] ${payment === 'grabpay' ? 'border-green-500' : 'border-transparent'}`} />
                                            <span className="absolute z-10 top-10 scale-0 transition-all rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 whitespace-nowrap">GrabPay</span>
                                        </div>
                                    </button>
                                </div>
                            </div>

                            <hr className='border-[1px] my-5' />

                            <div className="mb-5">
                                <div className='font-medium mb-3'>Payment Details:</div>

                                <div className='flex flex-row items-center gap-2'>
                                    <div className='w-[120px] text-xs'>Payment Method:</div><div className='font-bold'>{payment.toUpperCase()}</div>
                                </div>

                                <div className='flex flex-row items-center gap-2'>
                                    <div className='w-[120px]'>Subtotal:</div><div className='font-bold'>₱{total()?.subtotal}</div>
                                </div>

                                <div className='flex flex-row items-center gap-2'>
                                    <div className='w-[120px]'>Service fee:</div><div className='font-bold'>₱{total()?.fee}</div>
                                </div>

                                <div className='flex flex-row items-center gap-2'>
                                    <div className='w-[120px]'>Vat:</div><div className='font-bold'>₱{total()?.vat}</div>
                                </div>

                                <div className='flex flex-row items-center gap-2'>
                                    <div className='w-[120px]'>Total:</div><div className='text-blue-500 text-xl font-bold'>₱{total()?.total}</div>
                                </div>

                                <div className='mt-2 text-xs'>A confirmation receipt will be sent to <span className='font-bold'>{window.localStorage['email']}</span></div>
                            </div>

                            <hr className='border-[1px] mt-5 mb-1' />

                            <div>
                                <div className={`w-full text-center px-5 ${(!renewError) && 'hidden'}`}>
                                    <div className='text-red-500 italic text-sm'><div dangerouslySetInnerHTML={{ __html: renewError }} /></div>
                                </div>
                            </div>

                            <div className="mt-5 flex flex-row gap-2">
                                <div className="grow"></div>
                                <button disabled={renewLoading} onClick={() => setOpenDialog(false)} className={`border border-gray-300 rounded-md bg-white px-5 py-1 hover:bg-gray-200 active:bg-white shadow-md`}>Cancel</button>

                                <button disabled={renewLoading} onClick={submit} className={`rounded-md text-white bg-green-500 px-5 py-1 shadow-md hover:bg-green-600 active:bg-green-500`}>{renewLoading && <Spinner className='inline mr-2' />}Submit</button>
                            </div>

                        </DialogPanel>
                    </div>

                </div>

            </Dialog>

            <ConfirmDialog open={confirmDialog} setDialog={setConfirmDialog} onClickYes={renew} />

            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick={true}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                transition={Bounce}
            />

            <div className='xl:hidden'>
                {nav && <div className="bg-black/80 fixed w-full h-screen z-10 top-0 left-0" onClick={() => setNav(false)}></div>}
                <div className={nav ? "fixed top-0 left-0 w-[250px] h-screen bg-white z-10 duration-300" : "fixed top-0 left-[-100%] w-[250px] h-screen bg-white z-10 duration-300"}>
                    <UserNav nav='remote' />
                </div>
            </div>


        </>
    )
}


function ConfirmDialog({ open, setDialog, onClickYes }: any) {
    return (<Dialog open={open ?? false} onClose={setDialog} className="relative z-10">
        <DialogBackdrop
            transition
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-center justify-center text-center p-0">
                <DialogPanel
                    transition
                    className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                >
                    <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">

                            <div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-200 sm:mx-0 sm:h-10 sm:w-10`}>
                                <FaQuestion aria-hidden="true" className="h-6 w-6 text-blue-700" />
                            </div>

                            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">Confirm Subscription Renewal</DialogTitle>
                                <div className="mt-2">
                                    <div className="text-sm text-gray-700"><div dangerouslySetInnerHTML={{ __html: 'Please review your subscription renewal and payment details. If everything is correct, click "Yes" to complete your renewal. To make changes or cancel, click "No."' }} /></div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="bg-gray-50 px-4 py-3 flex flex-row gap-3">
                        <div className='grow'></div>

                        <div className={`flex flex-row gap-3`}>
                            <button
                                className='disabled:bg-gray-500 w-[80px] flex flex-row items-center justify-center bg-blue-600 px-3 py-1 text-sm font-medium rounded-md text-white shadow-sm hover:bg-blue-500 active:bg-blue-700'
                                onClick={() => {
                                    setDialog(false);
                                    onClickYes && onClickYes();
                                }}>Yes</button>
                            <button
                                type="button"
                                data-autofocus
                                onClick={() => setDialog(false)}
                                className="disabled:bg-gray-500 inline-flex w-[80px] justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                            >No</button>
                        </div>

                    </div>
                </DialogPanel>
            </div>
        </div>
    </Dialog>)
}

function Small({ items }: any) {

    return (<ul className="flex flex-col gap-2">
        {items?.map((item: any, index: number) => {

            const date = item.date ? moment(item.date).format('YYYY-MM-DD hh:mm:ss a') : '';

            return <li key={`${index}-ref`} className="flex flex-col gap-1 text-sm p-2 rounded-md border">

                <div className="flex flex-row items-center gap-1"><span>Date:</span><span className="font-semibold">{date}</span></div>
                <div className="flex flex-row items-center gap-1"><span>Action:</span><span className="font-semibold">{item.action.toUpperCase()}</span></div>
                <div className="flex flex-row items-center gap-1"><span>Quantity:</span><span className="font-semibold">{item.quantity}</span></div>
                <div className="flex flex-row items-center gap-1"><span>Unit:</span><span className="font-semibold">{item.unit}</span></div>
                <div className="flex flex-col sm:flex-row sm:items-center gap-1">
                    <span>Transaction Id:</span>
                    <span><a href={`/user/transaction/${item.transaction_id}`} className="font-semibold text-blue-500 hover:underline">{item.transaction_id}</a></span>
                </div>

            </li>
        })}
    </ul>)
}


function Large({ items }: any) {

    return (<table className="table-fixed w-full divide-y divide-gray-300 border">
        <thead className="text-left h-[40px]">
            <tr>
                <th className="font-medium px-3 w-[200px]">Date</th>
                <th className="font-medium px-3 w-[100px]">Action</th>
                <th className="font-medium px-3">Transaction Id</th>
                <th className="font-medium px-3 w-[100px]">Quantity</th>
                <th className="font-medium px-3 w-[100px]">Unit</th>
            </tr>
        </thead>
        <tbody className={`divide-y divide-gray-200`}>
            {items?.map((item: any, index: number) => {

                const date = item.date ? moment(item.date).format('YYYY-MM-DD hh:mm:ss a') : '';

                return <tr key={`${index}-ref`} className='h-[40px]'>
                    <td className="whitespace-nowrap px-3 text-sm">{date}</td>
                    <td className="whitespace-nowrap px-3 text-sm">{item.action?.toUpperCase()}</td>
                    <td className="whitespace-nowrap px-3 text-sm"><a href={`/user/transaction/${item.transaction_id}`} className="text-blue-500 hover:underline">{item.transaction_id}</a></td>
                    <td className="whitespace-nowrap px-3 text-sm text-center">{item.quantity}</td>
                    <td className="whitespace-nowrap px-3 text-sm">{item.unit}</td>
                </tr>
            })}
        </tbody>
    </table>);

}